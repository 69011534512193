import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import { Accordion } from 'react-bootstrap';

import { faqList } from 'assets/data/faq';

import 'assets/styles/pages/faq.scss';

const FAQ = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | FAQS' />
      <Layout>
        <div className='faq-section inner-pages pb-0'>
          <div className='container'>
            <div className='row '>
              <div className='col-md-12'>
                <section className='section'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='section-title'>
                          <h3 className='text-start mb-5'>FAQs</h3>
                        </div>
                      </div>
                    </div>
                    <Accordion>
                      {faqList.map((item) => {
                        return (
                          <Accordion.Item eventKey={item.id} key={item.id}>
                            <Accordion.Header>{item.heading}</Accordion.Header>
                            <Accordion.Body>
                              <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FAQ;
