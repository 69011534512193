export const faqList = [
  {
    id: 1,
    heading: `What is Forests By Heartfulness?`,
    body: `<p>Forests by Heartfulness (FBH) is an ecological movement envisioned to create spaces where humans coexist in harmony with Nature.</p>
      <p>Our vision is to conserve the native, endemic, threatened species of the country and enrich the mega-biodiversity while sequestering Carbon permanently from the atmosphere.</p>`,
  },
  {
    id: 2,
    heading: `What are the main objectives of FBH?`,
    body: `<h5>The programme has multiple objectives. Some of them include</h5>
      <ul>
        <li>Conserving India’s mega-biodiversity by planting 30 million native trees of rare, endangered and threatened (RET) variety over the next 5 years (2021-2025)</li>
        <li>Creating nationwide nurseries to grow highly resilient saplings that can compete and survive in hostile conditions while supporting livelihoods and micro entrepreneurs.</li>
        <li>Relieving farmer distress by partnering with them to grow trees of economic value on their farm bunds, thus insuring them against crop failures and providing alternative income.</li>
        <li>Growing plants of medicinal value to address supply-demand mismatch for medicinal plants in alternate treatments such as Ayurveda, Yoga, Naturopathy, Unani, Siddha and Homoeopathy (AYUSH)</li>
      </ul>`,
  },
  {
    id: 3,
    heading: `How can I contribute to “Forests By Heartfulness” movement?`,
    body: `<h5>There are several avenues, including :</h5>
    <ul>
      <li>Planting trees and ensuring their survival is resource intensive. You can contribute by adopting trees, so that FBH can plant trees on your behalf.</li>
      <li>Volunteering in one of the 18 nurseries. In some nurseries, residential facilities are available for volunteers.</li>
      <li>Arranging a plantation drive in your locality with local residents and volunteers and registering those saplings under FBH program</li>
      <li>Helping locate land pockets where tree saplings can be planted</li>
      <li>Fundraising, both from general public as well as corporates (CSR). All individual and corporate donations towards FBH are eligible for tax deductions.</li>
      <li>Putting us in touch with other NGOs who are on a similar journey</li>
    </ul>`,
  },
  {
    id: 4,
    heading: `How does FBH monitor the saplings planted?`,
    body: `<p>Monitoring saplings is crucial to ensure high level of survival, as well as learn lessons on what works and what doesn’t. FBH endeavors to monitor the saplings for at least 1 year. Every sapling would have a named caregiver who is responsible for watering, weeding, providing boosters to the sapling. We intend to partner with local NGOs and villages to provide the caregivers while local Heartfulness volunteers coordinate these activities.</p>
      <p>We are also building a mobile app to geo-tag the saplings and capture various growth parameters of the sapling. The app will be used by tree planters and caregivers for registering the saplings under FBH program and collecting vital information. The app will be released by Sep 2022.</p>
    `,
  },
  {
    id: 5,
    heading: `When do the saplings I donated or gifted get planted?`,
    body: `<p>This typically happens within 2 months of donation. We are working on an application that allows donors to view their sapling on a map and virtually monitor their growth. This app will be released by Sep 2022. Each sapling will have a unique donor.</p>
    `,
  },
  {
    id: 6,
    heading: `Do I get to choose the plant species of the saplings I donate?`,
    body: `<p>The FBH team chooses the species planted based on various parameters including local climatic conditions, soil type and objective of the plantation drive (for e.g. provide sanctuary to birds and butterflies). Thus, we select a wide variety of species instead of mono-cropping. For this reason, it is logistically difficult to allow donors to choose the species of the sapling.</p>
    `,
  },
  {
    id: 7,
    heading: `Do I get to choose the place where my saplings are planted?`,
    body: `<p>We are working on a feature to showcase list of identified plantation drives, so that donors can support the plantation drive of their choice. However, do not postpone your donation till then as we need funds immediately.</p>
    `,
  },
  {
    id: 8,
    heading: `How is Forests By Heartfulness different from other tree planting organizations?`,
    body: `<p>The size of the problem we are trying to solve is enormous and requires awareness and efforts from every citizen. FBH is one of many organizations striving to restore India’s biodiversity, so we do not consider ourselves exclusive or unique. However, we take pride in our ethics, honesty, transparency & openness and use of technology to make the maximum of the resources available.</p>
    `,
  },
  {
    id: 9,
    heading: `Where can I get more information about FBH?`,
    body: `<p>If you have any suggestions or queries, you may write to <a  href='mailto:fbh@heartfulness.org'>FBH@HEARTFULNESS.ORG</a> or send a Whatsapp message to +91 93910 03685.</p>
    `,
  },
];
